.site_header_mobile {
  @media only screen and (min-width: $bp-md) {
    display: none;
  }

  width: 100%;

  & nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: white;
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
  }

  & li {
    &:hover,
    &.active {
      color: $color-green;
    }

    a {
      display: block;
      color: inherit;
      text-decoration: none;
      padding: 15px;
      border-bottom: 1px dotted $color-whiteDark;
      font-weight: 600;
    }
  }
}

.site_header_mobile__logo {
  color: $color-greenDark;
  display: block;
  float: left;
  font-size: 1.5em;
  padding: 1em 0.5em;
  text-decoration: none;
}

.site_header_mobile__menu_input {
  display: none;

  &:checked {
    & ~ nav ul {
      max-height: none;
      max-height: 50vh;
      overflow: scroll;
    }

    & ~ .site_header_mobile__menu_label {
      .site_header_mobile__menu_icon {
        background: 0 0;

        &::before,
        &::after {
          top: 0;
        }

        &::before {
          transform: rotate(-45deg);
        }

        &::after {
          transform: rotate(45deg);
        }
      }
    }
  }
}

.site_header_mobile__menu_label {
  cursor: pointer;
  float: right;
  padding: 1.7em 1em;
  position: relative;
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.site_header_mobile__menu_text {
  padding: 0.4em;
  font-size: 0.8em;
}

.site_header_mobile__menu_icon {
  background: $color-greyDark;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;

  &::before,
  &::after {
    background: $color-greyDark;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  &::before {
    top: 5px;
  }

  &::after {
    top: -5px;
  }
}
