@font-face {
  font-family: flaticon;
  src: local(""), url("/vendors/fonts/Flaticon.woff2") format("woff2"),
    url("/vendors/fonts/Flaticon.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

[class^="flaticon-"]::before,
[class*=" flaticon-"]::before,
[class^="flaticon-"]::after,
[class*=" flaticon-"]::after {
  font-family: Flaticon, sans-serif;
  font-size: 20px;
  font-style: normal;
  margin-left: 20px;
}

.flaticon-arrow-point-to-right::before {
  content: "\f100";
}

.flaticon-play-button::before {
  content: "\f101";
}

.flaticon-video::before {
  content: "\f102";
}

.flaticon-leaf::before {
  content: "\f103";
}

.flaticon-leaf-1::before {
  content: "\f104";
}

.flaticon-leaf-2::before {
  content: "\f105";
}

.flaticon-tree-leaf::before {
  content: "\f106";
}

.flaticon-rating::before {
  content: "\f107";
}

.flaticon-shopping-cart::before {
  content: "\f108";
}

.flaticon-app-store::before {
  content: "\f109";
}

.flaticon-google-play::before {
  content: "\f10a";
}

.flaticon-delete::before {
  content: "\f10b";
}

.flaticon-check-mark::before {
  content: "\f10c";
}

.flaticon-cow::before {
  content: "\f10d";
}

.flaticon-milk-bottle::before {
  content: "\f10e";
}

.flaticon-cheese::before {
  content: "\f10f";
}

.flaticon-wheat::before {
  content: "\f110";
}

.flaticon-eggs::before {
  content: "\f111";
}

.flaticon-farm::before {
  content: "\f112";
}

.flaticon-windmill::before {
  content: "\f113";
}

.flaticon-watering-can::before {
  content: "\f114";
}

.flaticon-chicken::before {
  content: "\f115";
}

.flaticon-tractor::before {
  content: "\f116";
}

.flaticon-vegetables::before {
  content: "\f117";
}

.flaticon-forest::before {
  content: "\f118";
}

.flaticon-fence::before {
  content: "\f119";
}

.flaticon-musica-searcher::before {
  content: "\f11a";
}

.flaticon-arrow::before {
  content: "\f11b";
}

.flaticon-chat::before {
  content: "\f11c";
}

.flaticon-quote-two::before {
  content: "\f11d";
}

.flaticon-plus-sign::before {
  content: "\f11e";
}

.flaticon-minus::before {
  content: "\f11f";
}

.flaticon-design-tool::before {
  content: "\f120";
}

.flaticon-startup::before {
  content: "\f121";
}

.flaticon-tools::before {
  content: "\f122";
}

.flaticon-chat-1::before {
  content: "\f123";
}

.flaticon-quality::before {
  content: "\f124";
}

.flaticon-files::before {
  content: "\f125";
}

.flaticon-wireframe::before {
  content: "\f126";
}

.flaticon-video-player::before {
  content: "\f127";
}

.flaticon-monitor::before {
  content: "\f128";
}

.flaticon-text::before {
  content: "\f129";
}

.flaticon-design-tool-1::before {
  content: "\f12a";
}

.flaticon-settings::before {
  content: "\f12b";
}

.flaticon-picture::before {
  content: "\f12c";
}

.flaticon-shutter::before {
  content: "\f12d";
}

.flaticon-pantone::before {
  content: "\f12e";
}

.flaticon-pyramid::before {
  content: "\f12f";
}

.flaticon-crop::before {
  content: "\f130";
}

.flaticon-vector::before {
  content: "\f131";
}

.flaticon-mouse::before {
  content: "\f132";
}

.flaticon-tea-cup::before {
  content: "\f133";
}

.flaticon-undo::before {
  content: "\f134";
}

.flaticon-click::before {
  content: "\f135";
}

.flaticon-arrow-pointing-to-right::before {
  content: "\f136";
}

.flaticon-arrow-pointing-to-left::before {
  content: "\f137";
}

.flaticon-leaf-3::before {
  content: "\f138";
}

.flaticon-ui::before {
  content: "\f139";
}

.flaticon-quote::before {
  content: "\f13a";
}
