@import "../theme";

.page_button_block {
  text-align: center;
  margin: auto;
  display: table;

  a {
    display: block;
    border-radius: 5px;
    font-weight: bold;
    background-image: url("../../vendors/images/the-leaves-in-the-dew.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    line-height: 1.2em;
    color: white;
    text-decoration: none;
    text-shadow: 1px 1px 1px #000, 3px 3px 5px #0d5425;
    font-size: 20px;
    padding: 3em 1.3em;

    @media (min-width: $bp-md) {
      font-size: 30px;
      padding: 3rem;
      width: 8em;
      background-size: cover;
    }
  }
}
