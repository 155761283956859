@import "../../node_modules/open-props/open-props.min.css";

@import "./theme";
@import "./reset";
@import "./typography";
@import "./site-header";
@import "./site-navigation";
@import "./site-layout";
@import "./site-footer";
@import "./pagination";
@import "./header_banner";
@import "./blocks/banner";
@import "./blocks/carousel";
@import "./blocks/heading";
@import "./blocks/image";
@import "./blocks/page_button";
@import "./blocks/quote";
@import "./blocks/seperator";
@import "./blocks/text_and_image";
@import "./blocks/text";
@import "./blocks/group";
@import "./blocks/quick_guide";

.block {
  margin-top: var(--size-7);
  margin-bottom: var(--size-7);
  margin-left: auto;
  margin-right: auto;
}

.richtext-image {
  width: auto;
  height: auto;
}

.richtext-image.left {
  max-width: 25%;
  float: left;
  padding-right: 1rem;
}

.richtext-image.right {
  max-width: 25%;
  float: right;
  padding-left: 1rem;
}

.back_to_parent_link {
  display: block;
  font-weight: bold;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 2em;
  line-height: 1.2em;
  color: white;
  text-decoration: none;
  text-shadow: 1px 1px 1px #000, 3px 3px 5px #0d5425;
  width: 8em;
  background-image: url("../../vendors/images/the-leaves-in-the-dew-mirror.png");
  font-size: 18px;

  @media (min-width: 1024px) {
    font-size: 30px;
  }
}
