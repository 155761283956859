@import "../theme";

.heading_block {
  text-align: center;
  color: $color-greyDark;
  margin-bottom: 1rem;

  .heading_block__before_heading,
  .heading_block__heading,
  .heading_block__after_heading {
    word-break: unset;
  }

  &--type_1 {
    .heading_block__before_heading {
      font-family: "Open Sans", sans-serif;
      margin: 8px 0 0;
      font-size: 14px;
      font-weight: 600;
      color: #808080;
      letter-spacing: 0.1em;
    }

    .heading_block__heading {
      font-weight: 400;
      font-size: 40px;
      margin: 0.5rem 0 1rem;
      font-family: "Kaushan Script", sans-serif;

      @media (min-width: $bp-lg) {
        font-size: 60px;
      }
    }

    .heading_block__after_heading {
      color: inherit;
    }
  }

  &--type_2 {
    .heading_block__before_heading {
      color: inherit;
    }

    .heading_block__heading {
      color: inherit;
    }

    .heading_block__after_heading {
      color: inherit;
    }
  }

  &--type_3 {
    i.flaticon-leaf-3.flaticon-leaf-3 {
      color: $color-green;

      &::before,
      &::after {
        font-size: 30px;
        margin: 0;
      }
    }

    .heading_block__before_heading {
      color: inherit;
    }

    .heading_block__heading {
      font-size: 38px;
      font-weight: 600;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      margin: 0;

      &::before,
      &::after {
        content: "";
        display: block;
        background-color: #c7c7c7;
        width: 86px;
        height: 1px;
        margin: 0.5% 0.5em 0;
      }
    }

    .heading_block__after_heading {
      font-size: 14px;
      margin-top: 0;
      color: #808080;
      letter-spacing: 0.1em;
    }
  }
}
