@import "../theme";

.image_and_text_block {
  max-width: 940px;
  margin: auto auto 3rem;

  &__title {
    font-weight: 600;
    font-size: 26px;
    line-height: 1.3077em;
    margin: 38px 0 22px;
    word-wrap: break-word;
    word-break: break-word;
  }

  &__flex {
    display: flex;
    flex-direction: column;

    @media (min-width: $bp-md) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__text {
    a {
      color: $color-green;
      font-weight: 600;
    }
  }

  &__image {
    margin: 0 auto;
    border-radius: 5px;

    @media (max-width: $bp-md-1px) {
      margin: 1rem auto 0;
      min-width: 70%;
      height: auto;
    }

    @media (min-width: $bp-md) {
      margin: 0;
    }
  }

  &--left {
    .image_and_text_block__flex {
      flex-direction: column-reverse;

      @media (min-width: $bp-md) {
        flex-direction: row-reverse;
      }
    }

    .image_and_text_block__image {
      margin-bottom: 1rem;

      @media (min-width: $bp-md) {
        margin-right: 1rem;
      }
    }
  }

  &--right {
    .image_and_text_block__flex {
      @media (min-width: $bp-md) {
        flex-direction: row;
      }
    }

    .image_and_text_block__image {
      @media (min-width: $bp-md) {
        margin-left: 1rem;
      }
    }
  }

  ul,
  ol {
    margin-left: 1em;
  }
}
