.pagination {
  text-align: center;
  margin: 3em auto;
}

.pagination__page_links,
.pagination__page_numbers {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination__page_links {
  justify-content: space-between;
}

.pagination__page_numbers {
  justify-content: center;
}

.pagination__page_link {
  margin-bottom: 3em;
  padding: 2em 1em;
  background-color: #f4f6e8;
  border-radius: 5px;

  &--previous,
  &--next {
    font-weight: bold;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    line-height: 1.2em;
    color: white;
    text-decoration: none;
    text-shadow: 1px 1px 1px #000, 3px 3px 5px #0d5425;
    font-size: 20px;
    padding: 3em 1.3em;

    @media (min-width: $bp-md) {
      font-size: 30px;
      padding: 3rem;
      width: 8em;
      background-size: cover;
    }
  }

  &--previous {
    background-image: url("../../vendors/images/the-leaves-in-the-dew-mirror.png");
  }

  &--next {
    background-image: url("../../vendors/images/the-leaves-in-the-dew.png");
    margin-left: auto;
  }
}

.pagination__page_number {
  padding: 0.25em 0.5em;
  background-color: #f4f6e8;
  border-radius: 5px;
  margin: 0 0.25em;

  &--active {
    font-weight: bold;
  }
}
