@import "../theme";

.carousel_block {
  .image_card_list {
    .no-js & {
      display: flex;
      flex-wrap: wrap;
      gap: 3rem 1rem;
      justify-content: space-between;
    }
  }

  .image_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .no-js & {
      flex-basis: 100%;

      @media (min-width: $bp-sm) {
        flex-basis: 45%;
      }

      @media (min-width: $bp-lg) {
        flex-basis: 30%;
      }
    }

    a {
      display: block;
      text-decoration: none;
    }

    &__title {
      text-align: center;
    }

    &__image {
      border-radius: 5px;
      display: block;
      margin: auto;
    }
  }
}

.splide__pagination.splide__pagination {
  position: relative;
  bottom: initial;
  left: initial;
  transform: initial;
  background: $color-greenLight;
  padding: 6px 10px 10px;
  display: flex;
  margin: 1rem auto 0;
  width: max-content;
  border-radius: 5px;

  li {
    padding: 0 0.5em;
  }
}

.splide__pagination__page.splide__pagination__page {
  width: 10px;
  height: 10px;

  &.is-active {
    background-color: $color-green;
  }
}

.splide__arrow--prev.splide__arrow--prev {
  left: -1.8em;

  @media (min-width: $bp-md) {
    left: -2.5em;
  }
}

.splide__arrow--next.splide__arrow--next {
  right: -1.8em;

  @media (min-width: $bp-md) {
    right: -2.5em;
  }
}
