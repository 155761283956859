@import "../theme";

.image_block {
  figure {
    margin: 1em;
    margin: auto;
    padding: 1em;
    text-align: center;

    img {
      max-width: 100%;
      height: auto;
      margin: auto;
      display: block;
      border-radius: 5px;
    }
  }
}
