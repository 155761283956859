.quick_guide_block {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  background-size: cover;
  background-position: center;
  padding: var(--size-7);
  max-width: 60em;
  margin-left: auto;
  margin-right: auto;

  &.theme-light {
    --text-1: var(--gray-1);
    --text-shadow: 2px 2px 5px rgba(0, 0, 0, 1), -2px -2px 5px rgba(0, 0, 0, 1);

    .block p {
      background: rgba(0, 0, 0, 0.3);
      border-radius: var(--radius-blob-2);
    }
  }

  &.theme-dark {
    --text-1: var(--gray-9);
    --text-shadow: 2px 2px 5px rgba(255, 255, 255, 1), -2px -2px 5px rgba(255, 255, 255, 1);

    .block p {
      background: rgba(255, 255, 255, 0.3);
      border-radius: var(--radius-blob-2);
    }
  }

  .quick_guide_block__title.quick_guide_block__title {
    width: 100%;
    order: 2;
    font-size: var(--font-size-5);
    text-align: center;
    font-family: $font-3;

    @media (min-width: 1024px) {
      font-size: var(--font-size-7);
    }
  }

  .block {
    background: 0;
    margin: 0 auto;
    padding: var(--size-2);
    width: 100%;
    order: 3;
    color: var(--text-1);
    text-shadow: var(--text-shadow);
    font-family: var(--font-mono);
    text-align: center;
    margin-top: var(--size-3);
    margin-bottom: var(--size-3);
    margin-left: auto;
    margin-right: auto;
    max-width: 34ch;
    word-wrap: anywhere;
    word-break: break-word;

    @media (min-width: 480px) {
      width: calc(100% / 2);
    }
    @media (min-width: 1024px) {
      &:nth-child(2),
      &:nth-child(3) {
        order: 1;
      }
    }

    * {
      color: inherit;
      font-family: $font-3;
    }

    p {
      font-size: var(--font-size-3);

      @media (min-width: 1024px) {
        font-size: var(--font-size-4);
      }

      b {
        font-size: 1.4em;
      }

      a {
        font-size: inherit;
      }
    }
  }

  img {
    max-width: 100% !important;
  }
}
