.site-header {
  display: none;

  @media (min-width: $bp-md) {
    display: flex;
    max-width: 1170px;
    margin: auto;
    flex-direction: row;
  }

  > a {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    text-decoration: none;
  }

  h1 {
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
    padding: 0 1rem;
    color: $color-greenDark;
  }

  nav {
    display: flex;
    align-items: center;

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      justify-content: center;

      @media (min-width: $bp-md) {
        justify-content: flex-start;
      }
    }

    li {
      display: block;
      padding: 0.4em;
      margin: 0.4em;
      color: inherit;
      background-color: inherit;

      &.active,
      &:hover {
        color: $color-green;
      }
    }

    a {
      background-color: inherit;
      color: inherit;
      font-size: 14px;
      font-weight: 600;
      text-decoration: none;
    }
  }
}
