@import "../theme";

.seperator_block {
  img {
    max-width: 90%;
    height: auto;
    margin: 1rem auto;
    display: block;
  }
}
