@import "./font-icons";

/* https://google-webfonts-helper.herokuapp.com/fonts */

/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("/vendors/fonts/montserrat-v18-latin-regular.woff2") format("woff2"),
    url("/vendors/fonts/montserrat-v18-latin-regular.woff") format("woff");
}

/* montserrat-700italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: local(""), url("/vendors/fonts/montserrat-v18-latin-700italic.woff2") format("woff2"),
    url("/vendors/fonts/montserrat-v18-latin-700italic.woff") format("woff");
}

/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("/vendors/fonts/montserrat-v18-latin-700.woff2") format("woff2"),
    url("/vendors/fonts/montserrat-v18-latin-700.woff") format("woff");
}

/* kaushan-script-regular - latin */
@font-face {
  font-family: "Kaushan Script";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("/vendors/fonts/kaushan-script-v9-latin-regular.woff2") format("woff2"),
    url("/vendors/fonts/kaushan-script-v9-latin-regular.woff") format("woff");
}

/* Flaticon */
@font-face {
  font-family: "Flaticon";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("/vendors/fonts/Flaticon.woff2") format("woff2");
}

/* milonga-regular - latin */
@font-face {
  font-family: "Milonga";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("/vendors/fonts/milonga-v10-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/vendors/fonts/milonga-v10-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// /* montserrat-100 - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: normal;
//   font-weight: 100;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-100.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-100.woff") format("woff");
// }

// /* montserrat-100italic - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: italic;
//   font-weight: 100;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-100italic.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-100italic.woff") format("woff");
// }

// /* montserrat-200italic - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: italic;
//   font-weight: 200;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-200italic.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-200italic.woff") format("woff");
// }

// /* montserrat-200 - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: normal;
//   font-weight: 200;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-200.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-200.woff") format("woff");
// }

// /* montserrat-300 - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: normal;
//   font-weight: 300;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-300.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-300.woff") format("woff");
// }

// /* montserrat-300italic - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: italic;
//   font-weight: 300;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-300italic.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-300italic.woff") format("woff");
// }

// /* montserrat-italic - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: italic;
//   font-weight: 400;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-italic.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-italic.woff") format("woff");
// }

// /* montserrat-500 - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: normal;
//   font-weight: 500;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-500.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-500.woff") format("woff");
// }

// /* montserrat-500italic - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: italic;
//   font-weight: 500;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-500italic.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-500italic.woff") format("woff");
// }

// /* montserrat-600 - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: normal;
//   font-weight: 600;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-600.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-600.woff") format("woff");
// }

// /* montserrat-600italic - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: italic;
//   font-weight: 600;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-600italic.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-600italic.woff") format("woff");
// }

// /* montserrat-800 - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: normal;
//   font-weight: 800;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-800.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-800.woff") format("woff");
// }

// /* montserrat-800italic - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: italic;
//   font-weight: 800;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-800italic.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-800italic.woff") format("woff");
// }

// /* montserrat-900 - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: normal;
//   font-weight: 900;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-900.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-900.woff") format("woff");
// }

// /* montserrat-900italic - latin */
// @font-face {
//   font-family: "Montserrat";
//   font-style: italic;
//   font-weight: 900;
//   src: local(""),
//     url("/vendors/fonts/montserrat-v18-latin-900italic.woff2") format("woff2"),
//     url("/vendors/fonts/montserrat-v18-latin-900italic.woff") format("woff");
// }
