@import "./fonts";

@mixin header-styles {
  font-family: $font-primary;
  word-wrap: break-word;
  word-break: break-word;

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  @include header-styles;

  font-weight: 600;
  line-height: 1.2em;
  margin: 30px 0 -5px;
}

h2 {
  @include header-styles;

  font-weight: 600;
  font-size: 32px;
  line-height: 1.375em;
  margin: 32px 0 19px;
  word-wrap: break-word;
  word-break: break-word;
}

h3,
.h3 {
  @include header-styles;

  font-weight: 600;
  font-size: 26px;
  line-height: 1.3077em;
  margin: 38px 0 22px;
  word-wrap: break-word;
  word-break: break-word;
}

h4 {
  @include header-styles;

  font-weight: 600;
  font-size: 20px;
  line-height: 1.35em;
  margin: 35px 0 23px;
  word-wrap: break-word;
  word-break: break-word;
}

h5 {
  @include header-styles;

  font-weight: 600;
  font-size: 16px;
  line-height: 1.625em;
  margin: 37px 0 22px;
  word-wrap: break-word;
  word-break: break-word;
}

h6 {
  @include header-styles;

  font-weight: 600;
  font-size: 14px;
  line-height: 1.5714em;
  margin: 37px 0 24px;
  word-wrap: break-word;
  word-break: break-word;
}

p,
li,
a,
address,
label,
input,
select,
textarea,
button {
  color: $color-greyDark;
  font-size: 16px;
}

ul,
ol {
  margin-left: 0;
  margin-top: 1.6875em;
  padding-left: 0;
}

ol {
  list-style: decimal;
  list-style-position: inside;
}

ul ul,
ol ul {
  margin-top: 0.25em;
  margin-left: 15px;
}

ol ol,
ul ol {
  margin-top: 0.25em;
  margin-left: 30px;
}

ul > li {
  padding-left: 18px;
  text-indent: 0;
  position: relative;
  line-height: 1.75em;
}

ol > li {
  padding-left: 0;
  text-indent: 0;
  position: relative;
  line-height: 1.75em;
}

ul > li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.8125em;
  margin-top: -1px;
  font-size: inherit;
  width: 5px;
  height: 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

ul > li + li,
ol > li + li {
  margin-top: 0.5em;
}

p {
  margin: 1.1875em 0 0;
}

p:first-child {
  margin: 0;
}

p[style*="font-size"] {
  line-height: 1.2em;
}

hr {
  border: 0;
  border-top: 1px solid #c7c7c7;
}

a:focus {
  outline: dotted 1px;
}

i {
  font-style: italic;
}
