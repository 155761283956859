@import "../theme";

.text_block {
  margin-bottom: 1rem;

  &--left {
    text-align: left !important;
  }

  &--center {
    text-align: center !important;
  }

  &--right {
    text-align: right !important;
  }

  a {
    color: $color-green;
    font-weight: 600;
  }

  ul,
  ol {
    margin-left: 1em;
  }
}
