.site-footer {
  min-height: 320px;
  padding: 5rem 1rem;
  background-size: initial;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: bottom center;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 1rem;
    }

    h5 {
      color: $color-greenDark;
      font-weight: 600;
      margin: 0;
    }
  }

  small * {
    font-size: 14px;
    margin: 1rem auto;
    max-width: 56em;
    text-align: center;
    width: 90%;
  }
}

.site-footer .social_links__list {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
}

.site-footer .social_links__item {
  padding: 0 0.4em;
}

.site-footer .social_links__anchor {
  display: block;
}
