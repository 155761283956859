@import "./theme";

.header-banner {
  background-color: $color-greenLight;
  mask-image: url("/vendors/images/header-bottom.svg");
  mask-position: bottom;
  mask-repeat: no-repeat;
  mask-size: cover;
  margin: -1em -1em 0;

  &_image {
    background-attachment: scroll;
    background-image: url("/vendors/images/bg_345.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    max-height: 40vh;
  }

  &_text {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    margin: auto;
  }

  &_title {
    margin-top: 0;
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  list-style: none;

  li {
    margin: 0;
    padding: 0;

    &:not(:first-of-type) {
      padding-left: 1em;
    }

    &.active,
    &:hover {
      color: $color-green;
      font-weight: 600;
    }

    span {
      color: $color-greyDark;
      font-weight: 600;
      margin-left: 0.6em;
    }
  }

  a {
    margin: 0;
    color: inherit;
    text-decoration: none;
  }
}
