.banner_block {
  background-color: $color-greenLight;
  mask-image: url("/vendors/images/header-top.svg"), url("/vendors/images/header-bottom.svg");
  mask-position: top, bottom;
  mask-repeat: no-repeat, no-repeat;
  mask-size: cover, cover;
  mask-composite: intersect;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;

  &_image {
    background-attachment: scroll;
    background-image: url("/vendors/images/bg_345.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    min-height: 170px;

    @media (min-width: $bp-lg) {
      min-height: 340px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    margin: 2rem auto;
    max-width: 1024px;
    padding: 2rem 1rem;

    @media (min-width: $bp-md) {
      padding: 2rem;
    }

    @media (min-width: $bp-lg) {
      padding: 3rem;
    }
  }
}
