.site-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.site-main {
  background: $color-white;
  padding: 1em;
  position: relative;
  flex-grow: 1;

  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

.main-content-wrapper {
  z-index: 1;
  position: relative;
}

.main-content-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  opacity: 0.2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

section {
  margin: auto;
  padding: 0 1rem;

  @media (min-width: $bp-md) {
    padding: 0 2rem;
  }

  @media (min-width: $bp-lg) {
    padding: 0 3rem;
  }
}

.embed_block_wrapper {
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $bp-sm) {
    max-width: $width-sm;
  }

  @media (min-width: $bp-md) {
    max-width: $width-md;
  }

  @media (min-width: $bp-lg) {
    max-width: $width-lg;
  }

  @media (min-width: $bp-xl) {
    max-width: $width-xl;
  }
}
