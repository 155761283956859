/* GroupBlock */
.group_block {
  align-items: center;
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  padding: var(--size-8);

  .block {
    background: 0;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    flex: 10 10 100px;

    @media (min-width: 480px) {
      width: calc(100% / 2);
    }
    @media (min-width: 1024px) {
      width: calc(100% / 3);
    }

    * {
      color: inherit;
    }
  }
  .image_block--small {
    flex-basis: 100px;
  }
  .image_block--medium {
    flex-basis: 200px;
  }
  .image_block--large {
    flex-basis: 300px;
  }

  &--narrow {
    .block {
      @media (min-width: 360px) {
        width: calc(100% / 2);
      }
      @media (min-width: 480px) {
        width: calc(100% / 3);
      }
    }
  }

  &--wide {
    .block {
      @media (min-width: 768px) {
        width: calc(100% / 2);
      }
    }
  }

  img {
    max-width: 100% !important;
  }
}
