@import "../theme";

.quote_block {
  background: var(--surface-3);
  margin: var(--size-3) auto;
  text-align: center;
  padding: var(--size-7) var(--size-3);
}
.quote_block__quote {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-7);
  font-style: italic;

  &::after {
    clear: both;
  }

  p {
    font-size: inherit;
    display: inline;
  }
}
.quote_block__cite {
  text-align: right;
  display: block;
  font-size: var(--font-size-3);
  margin: var(--font-size-2) 0 0;
  font-weight: bold;

  &::before {
    content: "– ";
    display: inline;
  }
}
.quote_block__link {
  text-decoration: underline;
}
